import type { ReactNode } from 'react';

export interface Heading1Props {
    children: ReactNode;
    level?: number;
    className?: string;
}

export default function Heading1({ children, className, level = 1 }: Heading1Props): JSX.Element {
    return (
        <h1
            className={`font-sans text-2xl md:text-3xl lg:text-5xl !leading-tight font-bold mt-5 mb-[0.8em] text-gray-900 dark:text-white ${className}`}
            aria-level={level}>
            {children}
        </h1>
    );
}
