import type { IPublication } from '../../publication.interface';

export function getItemShowPublicationPosts(publicationId: IPublication['id']): string {
    return `showPublication${publicationId}Posts`;
}

export function updateShowPublicationPosts(
    publicationId: IPublication['id'],
    status: boolean,
): void {
    localStorage.setItem(getItemShowPublicationPosts(publicationId), status.toString());
}
